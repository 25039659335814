import { AuthProvider as IAuthProvider } from 'ra-core';
import DecodeJwt from 'jwt-decode';

export interface ILoginParams {
    username: string,
    password: string
}

export class AuthProvider implements IAuthProvider {
    login(params: ILoginParams){
        const request = new Request('https://masterpassword.dev.0x01.ch/api/login', {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(params),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ jwt }) => {
                const decodedToken: any = DecodeJwt(jwt);

                localStorage.setItem('expires', decodedToken.exp);
                localStorage.setItem('token', jwt);
                localStorage.setItem('roles', JSON.stringify(decodedToken.data.roles));
            });
    }
    logout(params: any){
        localStorage.removeItem('expires');
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        return Promise.resolve();
    }
    checkAuth(params: any){
        var expires = localStorage.getItem('expires') ?? "0";
        var expired = (parseInt(expires) * 1000 - Date.now() < 0);
        return !expired ? Promise.resolve() : Promise.reject();
    }
    checkError(params: any){
        return Promise.resolve()
    }
    getPermissions(params: any){
        const roles = localStorage.getItem('roles');
        const role = roles == null ? null: JSON.parse(roles);

        return role ? Promise.resolve(role) : Promise.reject();
    }
}

export default AuthProvider;