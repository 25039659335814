import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import { Pagination } from '../../layout'
import { ListImportButton } from '../buttons';
 
export const GroupList = (props: any) => (
    <List {...props} pagination={<Pagination />} perPage={50}>
        <Datagrid optimized rowClick="edit">
            <TextField source="name" />
            {/*<ListImportButton />*/}
            <EditButton />
        </Datagrid>
    </List>
);

export default GroupList;