/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    TabbedForm,
    FormTab,
    required,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    ReferenceField,
    BooleanInput,
    TextInput,
    PasswordInput,
    ReferenceArrayInput,
    ReferenceManyField,
    AutocompleteArrayInput,
    UrlField
} from 'react-admin';
import { Pagination } from '../../layout'

const UserEdit = ({ permissions, ...props }: any) => (
    <Edit
        {...props}
    >
        <TabbedForm>
            <FormTab label="Details">
                <TextInput disabled source="id" />
                <TextInput
                    source="name"
                    validate={required()}
                />
                <TextInput source="firstName" />
                <TextInput source="lastName" />
                <PasswordInput source="password" />
                <BooleanInput source="isAdmin" />
                <ReferenceArrayInput source="groupIds" reference="groups">
                    <AutocompleteArrayInput  optionText="name" />
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Assignments" path="assignments">
                <ReferenceManyField
                    reference="assignments"
                    target="userId"
                    addLabel={false}
                    pagination={<Pagination />}
                    fullWidth
                >
                    <Datagrid>
                        <ReferenceField label="App" source="appId" reference="apps">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="User" source="userId" reference="users">
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="username" />
                        <TextField source="password" />
                        <EditButton />
                        <DeleteButton />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Favorites" path="favorites">
                <ReferenceManyField
                    reference="favorites"
                    target="userId"
                    addLabel={false}
                    pagination={<Pagination />}
                    fullWidth
                >
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="description" />
                        <UrlField source="link" />
                        <EditButton />
                        <DeleteButton />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
);

UserEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default UserEdit;