/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    SimpleForm,
    required,
    TextInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

const FavoriteEdit = ({ permissions, ...props }: any) => (
    <Edit
        {...props}
    >
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="userId" reference="users">
                <AutocompleteInput  optionText="name" validate={required()} />
            </ReferenceInput>
            <TextInput
                source="name"
                validate={required()}
            />
            <TextInput source="link" validate={required()} />
            <TextInput  source="description" />
        </SimpleForm>
    </Edit>
);

FavoriteEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default FavoriteEdit;