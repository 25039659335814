/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Create,
    SimpleForm,
    required,
    BooleanInput,
    PasswordInput,
    TextInput,
    AutocompleteArrayInput,
    ReferenceArrayInput
} from 'react-admin';

const UserCreate = ({ permissions, ...props }: any) => (
    <Create
        {...props}
    >
        <SimpleForm>
                <TextInput
                    source="name"
                    validate={required()}
                />
                <TextInput source="firstName" />
                <TextInput source="lastName" />
                <PasswordInput source="password" validate={required()} />
                <BooleanInput source="isAdmin" />
                <ReferenceArrayInput source="groupIds" reference="groups">
                    <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);

UserCreate.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default UserCreate;