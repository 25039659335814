import React from 'react';
import { List, Datagrid, UrlField , TextField, EditButton, Filter, SearchInput } from 'react-admin';
import { ListImportButton } from "../buttons";
import { Pagination } from '../../layout'

const AppFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);
 
export const AppList = (props: any) => (
    <List {...props} filters={<AppFilter />} pagination={<Pagination />} perPage={50}>
        <Datagrid optimized rowClick="edit">
            <TextField source="name" />
            <UrlField source="link" />
            <TextField source="description" />
            <ListImportButton />
            <EditButton />
        </Datagrid>
    </List>
);

export default AppList;