import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, SimpleForm, FileInput, FileField, BooleanInput, Toolbar, SaveButton, useDataProvider } from 'react-admin';
import ImportIcon from '@material-ui/icons/Publish';

const ImportToolBar = (props: any) => {
const dataProvider = useDataProvider();

const submitAction = (params: any) => {
    dataProvider.import('users/import', params);
}


 return <Toolbar {...props} >
   <SaveButton label="Import" icon={<ImportIcon />} redirect="/"
  onSave={submitAction} />
 </Toolbar>
};

const ImportUsers = () => (
    <Card>
        <Title title="Import Users" />
        <CardContent>
            <SimpleForm toolbar={<ImportToolBar />}>
                <FileInput source="files" label="Users" accept=".csv" multiple={false}>
                    <FileField source="src" title="title" />
                </FileInput>
                <BooleanInput source="skipFirstLine" label="Skip first line" initialValue={true} />
                <BooleanInput source="overwrite" label="Overwrite existing users" initialValue={true} />
                <BooleanInput source="createGroups" label="Create not existing groups" initialValue={false} />
            </SimpleForm>
        </CardContent>
    </Card>
);

export default ImportUsers;