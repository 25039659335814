import React, { FC, CSSProperties } from 'react';
import { useMediaQuery, Theme } from '@material-ui/core'
import { List, Datagrid, TextField, ReferenceField } from 'react-admin'
import { Pagination } from '.';
import { LinkButton, CopyButton } from '../components/buttons'

let fakeProps = {
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null
}

let assignmentProps = {
    ...fakeProps,
    basePath: "/dashboard/assignments",
    resource: "dashboard/assignments"
}

let favoriteProps = {
    ...fakeProps,
    basePath: "/dashboard/favorites",
    resource: "dashboard/favorites"
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const AssignmentList : FC = () => <List {...assignmentProps} pagination={<Pagination />} exporter={false} bulkActionButtons={false} title=" ">
<Datagrid optimized>
    <ReferenceField label="App" source="appId" reference="apps" link={false}>
        <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="Link" source="appId" reference="apps" link={false}>
        <LinkButton source="link" />
    </ReferenceField>                
    <TextField source="username" />
    <CopyButton source="username" />
    <TextField source="password" sortable={false} />
    <CopyButton source="password" />
</Datagrid>
</List>;



const FavoritesList: FC = () => {
    return <List {...favoriteProps} pagination={<Pagination />} exporter={false} bulkActionButtons={false} title="Dashboard">
            <Datagrid optimized>             
                <TextField source="name" />
                <TextField source="description" />
                <LinkButton source="link" />
            </Datagrid>
        </List>; 
};
 
const Dashboard: FC = () => {

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    return isXSmall || isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                <FavoritesList />
            </div>
            <div style={styles.singleCol}>
                <AssignmentList />
            </div>
        </div>
    ) : (
    <div style={styles.flex}>
        <div style={styles.leftCol}>
            <FavoritesList />
        </div>
        <div style={styles.rightCol}>
            <AssignmentList />
        </div>
    </div>
    );
};

export default Dashboard;