/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Create,
    SimpleForm,
    required,
    TextInput,
} from 'react-admin';

const GroupCreate = ({ permissions, ...props }: any) => (
    <Create
        {...props}
    >
        <SimpleForm>
                <TextInput
                    source="name"
                    validate={required()}
                />
        </SimpleForm>
    </Create>
);

GroupCreate.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default GroupCreate;