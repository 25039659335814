import React, { FC,  ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Record } from 'ra-core';
import { Button, Link } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import get from 'lodash/get';
import LinkIcon from '@material-ui/icons/OpenInNew';

const defaultIcon = <LinkIcon />;

// useful to prevent click bubbling in a datagrid with rowClick
const openInNewWindow = (e: any, href: string) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(href,'_blank'); return false;
}

interface ButtonProps {
        alignIcon?: 'left' | 'right';
        children?: ReactElement;
        classes?: object;
        className?: string;
        color?: any;
        disabled?: boolean;
        label?: string;
        size?: 'small' | 'medium' | 'large';
}

interface Props {
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
    source?: any;
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

const LinkButton: FC<EditButtonProps> = ({
    basePath = '',
    label = 'Import',
    record,
    source,
    icon = defaultIcon,
    ...rest
}) => {
    const value = get(record, source);

    return <Button
        component={Link}
        href={value}
        label={label}
        onClick={e => openInNewWindow(e, value)}
        {...rest as any}
    >
        {icon}
    </Button>
};

LinkButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default LinkButton;