import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import { Button } from 'react-admin'

import ImportIcon from '@material-ui/icons/Publish';

const defaultIcon = <ImportIcon />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();

interface ButtonProps {
        alignIcon?: 'left' | 'right';
        children?: ReactElement;
        classes?: object;
        className?: string;
        color?: any;
        disabled?: boolean;
        label?: string;
        size?: 'small' | 'medium' | 'large';
}

interface Props {
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

const ListImportButton: FC<EditButtonProps> = ({
    basePath = '',
    label = 'Import',
    record,
    icon = defaultIcon,
    ...rest
}) => (
    <Button
        component={Link}
        to={`${linkToRecord(basePath, record && record.id)}/import`}
        label={label}
        onClick={stopPropagation}
        {...rest as any}
    >
        {icon}
    </Button>
);

ListImportButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default ListImportButton;