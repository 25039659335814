import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

const httpClient = (url: any, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  };

const dataProvider = jsonServerProvider('https://masterpassword.dev.0x01.ch/api', httpClient);

const handleImport = (resource: string, params: any, dataProviderCallback: any) => {
    const importFile = params.data.files;
        if (importFile === undefined || !(importFile.rawFile instanceof File)){
            return Promise.reject('Error: Not a file...'); // Didn't test this...
        }

        delete params.data.files;

        return Promise.resolve( convertFileToBase64(importFile) ) 
            .then( (file64) => ({
                src: file64,
                title: `${importFile.title}`
            }))
            .then( transformedMyFile => dataProviderCallback( resource, {
                ...params,
                data: {
                    ...params.data,
                    importFile: transformedMyFile
                }
            }));
}

const extendedDataProvider = { ...dataProvider,
    update: (resource: string, params: any) => {
        if (resource !== 'apps' || !params.data.files) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
        /**
         * For posts update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */
        
        return handleImport(resource, params, dataProvider.update);
    },
    import: (resource: string, data: any) => {
        let params = { data: data };

        return handleImport(resource, params, dataProvider.create);
    }
 }

 interface IFileObject {
     rawFile: File
 }

 /**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file: IFileObject) =>
new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
});


export default extendedDataProvider;