import * as React from 'react';
import { Fragment, useState } from 'react';
import AddGroupIcon from '@material-ui/icons/GroupAdd';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    SimpleForm,
    Create
} from 'react-admin';

import { ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';

const defaultIcon = <AddGroupIcon />;

const AddGroupButton = ({ selectedIds }:any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'posts',
        selectedIds,
        { views: 0 },
        {
            onSuccess: () => {
                refresh();
                notify('Posts updated');
                unselectAll('posts');
            },
            onFailure: (error: any) => notify('Error: posts not updated', 'warning'),
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label="Add group" onClick={handleClick} >{defaultIcon}</Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title="Add group to users"
                content={<ReferenceArrayField label="Groups" reference="groups" source="groupIds" sortable={false}>
                <SingleFieldList >
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>} //"Are you sure you want to add the group ?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default AddGroupButton;