/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    TabbedForm,
    required,
    TextInput,
    FormTab,
    ReferenceManyField,
    Datagrid,
    EditButton,
    ReferenceField,
    TextField,
    BooleanInput,
    FileInput,
    FileField,
    DeleteButton
} from 'react-admin';
import { Pagination } from '../../layout'

const AppEdit = ({ permissions, ...props }: any) => (
    <Edit
        {...props}
    >
        <TabbedForm>
            <FormTab label="Details">
                <TextInput disabled source="id" />
                <TextInput
                    source="name"
                    validate={required()}
                />
                <TextInput source="link" validate={required()} />
                <TextInput  source="description" />
            </FormTab>
            <FormTab label="Assignments" path="assignments">
                    <ReferenceManyField
                        reference="assignments"
                        target="appId"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                    >
                        <Datagrid>
                            <ReferenceField label="App" source="appId" reference="apps">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="User" source="userId" reference="users">
                                <TextField source="name" />
                            </ReferenceField>
                            <TextField source="username" />
                            <TextField source="password" />
                            <EditButton />
                            <DeleteButton />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab label="Import assignments" path="import">
                    <FileInput source="files" label="Assignments" accept=".csv" multiple={false}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <BooleanInput source="createUser" label="Create not existing users" />
                    <BooleanInput source="skipFirstLine" label="Skip first line" initialValue={true} />
                    {/*<BooleanInput source="overwrite" label="Overwrite existing assignments" initialValue={true} />*/}
                </FormTab>
        </TabbedForm>
    </Edit>
);

AppEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default AppEdit;