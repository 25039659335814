/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    TabbedForm,
    FormTab,
    required,
    TextInput,
    ReferenceManyField,
    Pagination,
    Datagrid,
    ReferenceField,
    TextField,
    EditButton,
    DeleteButton,
    FileInput,
    FileField,
    BooleanInput
} from 'react-admin';

const GroupEdit = ({ permissions, ...props }: any) => (
    <Edit
        {...props}
    >
        <TabbedForm>
            <FormTab label="Details">
                <TextInput disabled source="id" />
                <TextInput
                    source="name"
                    validate={required()}
                />
            </FormTab>
            <FormTab label="Users" path="users">
                <ReferenceManyField
                        reference="users"
                        target="groupIds"
                        addLabel={false}
                        pagination={<Pagination />}
                        fullWidth
                    >
                        <Datagrid>
                            <TextField source="name" />
                            <TextField source="firstName" />
                            <TextField source="lastName" />
                            <EditButton />
                            <DeleteButton />
                        </Datagrid>
                    </ReferenceManyField>
            </FormTab>
            {/*<FormTab label="Import users" path="import">
                <FileInput source="files" label="Assignments" accept=".csv" multiple={false}>
                    <FileField source="src" title="title" />
                </FileInput>
                <BooleanInput source="skipFirstLine" label="Skip first line" initialValue={true} />
                <BooleanInput source="overwrite" label="Overwrite existing users" initialValue={true} />
                <BooleanInput source="createGroups" label="Create not existing groups" initialValue={false} />
            </FormTab>*/}
        </TabbedForm>
    </Edit>
);

GroupEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default GroupEdit;