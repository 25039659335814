/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    SimpleForm,
    required,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    PasswordInput
} from 'react-admin';

const AssignmentEdit = ({ permissions, ...props }: any) => (
    <Edit
        {...props}
    >
        <SimpleForm>
                <TextInput disabled source="id" />
                <ReferenceInput source="userId" reference="users">
                    <AutocompleteInput  optionText="name" validate={required()} />
                </ReferenceInput>
                <ReferenceInput source="appId" reference="apps">
                    <AutocompleteInput  optionText="name" validate={required()} />
                </ReferenceInput>
                <TextInput
                    source="username"
                    validate={required()}
                />
                <PasswordInput
                    source="password"
                    validate={required()}
                />
        </SimpleForm>
    </Edit>
);

AssignmentEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default AssignmentEdit;