import React from 'react';
import { List, Datagrid, TextField, EditButton, UrlField, ReferenceField, Filter, ReferenceInput, AutocompleteInput } from 'react-admin';
import FavoriteListActions from './FavoriteListActions';
import { Pagination } from '../../layout'

const FavoriteFilter = (props: any) => (
    <Filter {...props}>
        {/*<SearchInput source="q" alwaysOn />
        <NullableBooleanInput source="isAdmin" />*/}
        <ReferenceInput label="User" source="userId" reference="users"  alwaysOn>
            <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Group" source="groupIds" reference="groups" alwaysOn>
            <AutocompleteInput  optionText="name" />
        </ReferenceInput>
    </Filter>
);
 
export const FavoriteList = (props: any) => (
    <List {...props} filters={<FavoriteFilter />} pagination={<Pagination />} actions={<FavoriteListActions />}  perPage={50}>
        <Datagrid optimized rowClick="edit">
            <ReferenceField label="User" source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <UrlField source="link" />
            <EditButton />
        </Datagrid>
    </List>
);

export default FavoriteList;