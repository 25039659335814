import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, Filter, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Pagination } from '../../layout'

const AssignmentFilter = (props: any) => (
    <Filter {...props}>
        {/*<SearchInput source="q" alwaysOn />
        <NullableBooleanInput source="isAdmin" />*/}
        <ReferenceInput label="App" source="appId" reference="apps" alwaysOn>
            <AutocompleteInput  optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="User" source="userId" reference="users" alwaysOn>
            <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Group" source="groupIds" reference="groups" alwaysOn>
            <AutocompleteInput  optionText="name" />
        </ReferenceInput>
    </Filter>
);
 
export const AssignmentList = (props: any) => (
    <List {...props} filters={<AssignmentFilter />} pagination={<Pagination />} perPage={50}>
        <Datagrid optimized rowClick="edit">
            <ReferenceField label="App" source="appId" reference="apps">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="User" source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="username" />
            <TextField source="password" sortable={false} />
            <EditButton />
        </Datagrid>
    </List>
);

export default AssignmentList;