import React, { FC, ReactElement } from 'react';
import { useNotify, Button } from 'react-admin';
import CopyIcon from '@material-ui/icons/Assignment';
import PropTypes from 'prop-types';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Record } from 'ra-core';

interface ButtonProps {
    alignIcon?: 'left' | 'right';
    children?: ReactElement;
    classes?: object;
    className?: string;
    color?: any;
    disabled?: boolean;
    label?: string;
    size?: 'small' | 'medium' | 'large';
}

const fallbackCopyTextToClipboard = (text: string) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

const copyTextToClipboard = (text: string, notify: any) => {

    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text)
    .then(response => {
        notify('Copied to clipboard.');
    })
    .catch(error => {
        // failure side effects go here 
        notify(`Could not copy to clipboard, error: ${error.message}`, 'warning');
    });
}

const CopyButton: FC<CopyButtonProps> = ({
    basePath = '',
    label = 'Copy',
    record,
    source = 'password',
    icon = defaultIcon,
    ...rest
}) => {
    const notify = useNotify();

    const copy = (e: any) => {
        // useful to prevent click bubbling in a datagrid with rowClick
        e.stopPropagation();

        if(record === undefined) return;
        copyTextToClipboard(record[source as keyof Record], notify);
    }

    return <Button
    variant="contained"
        label={label}
        onClick={copy}
        {...rest as any}
    >
        {icon}
    </Button>
};

const defaultIcon = <CopyIcon />;

interface Props {
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
    source?: string;
}

export type CopyButtonProps = Props & ButtonProps & MuiButtonProps;

CopyButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default CopyButton;