import React from 'react'
import { Route } from 'react-router-dom'
import { ImportUsers } from '../components/users'
import { ImportFavorites } from '../components/favorites'

const ImportUserRoute = <Route exact path="/users/import" component={ImportUsers} />;
const ImportFavoriteRoute = <Route exact path="/favorites/import" component={ImportFavorites} />;

export default [
    ImportUserRoute,
    ImportFavoriteRoute
];