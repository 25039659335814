import React from 'react';
import { Admin, Resource}  from 'react-admin';

import { AuthProvider } from './auth/AuthProvider'
import { Login, Dashboard } from './layout'
import extendedDataProvider from './ExtendedDataProvider'

import { UserList, UserEdit, UserCreate, UserIcon } from './components/users'
import { AppList, AppEdit, AppCreate, AppIcon } from './components/apps'
import { GroupList, GroupEdit, GroupCreate, GroupIcon } from './components/groups'
import { AssignmentList, AssignmentEdit, AssignmentCreate, AssignmentIcon } from './components/assignment'
import { FavoriteList, FavoriteEdit, FavoriteCreate, FavoriteIcon } from './components/favorites'
import CustomRoutes from './layout/CustomRoutes'

import './App.css';

const isAdmin = (permissions: [string]) =>
{
  return permissions.indexOf('admin') >= 0;
};

// 'HelloProps' describes the shape of props.
// State is never set so we use the '{}' type.
export class App extends React.Component<{}, {}> {
  render() {
      let authProvider = new AuthProvider();

      return <Admin 
       authProvider={authProvider}
       loginPage={Login}
       dataProvider={extendedDataProvider} 
       customRoutes={CustomRoutes}
       dashboard={Dashboard}
       title="Masterpassword"
      >
        {(permissions: [string]) => {
          return [
          <Resource name="users" options={{ label: 'Users' }} list={isAdmin(permissions) ? UserList : null} edit={isAdmin(permissions) ? UserEdit : null} create={isAdmin(permissions) ? UserCreate : null} icon={UserIcon} />,
          <Resource name="groups" options={{ label: 'Groups' }} list={isAdmin(permissions) ? GroupList : null} edit={isAdmin(permissions) ? GroupEdit : null} create={isAdmin(permissions) ? GroupCreate : null} icon={GroupIcon} />,
          <Resource name="apps" options={{ label: 'Apps' }} list={isAdmin(permissions) ? AppList : null} edit={isAdmin(permissions) ? AppEdit : null} create={isAdmin(permissions) ? AppCreate : null} icon={AppIcon} />,
          <Resource name="assignments" options={{ label: 'Assignments' }} list={isAdmin(permissions) ? AssignmentList : null} edit={isAdmin(permissions) ? AssignmentEdit : null} create={isAdmin(permissions) ? AssignmentCreate : null} icon={AssignmentIcon} />,
          <Resource name="favorites" options={{ label: 'Favorites' }} list={isAdmin(permissions) ? FavoriteList : null} edit={isAdmin(permissions) ? FavoriteEdit : null} create={isAdmin(permissions) ? FavoriteCreate : null} icon={FavoriteIcon} />,
          <Resource name="dashboard/assignments" list={null} />,
          <Resource name="dashboard/favorites" list={null} />
        ]}}
      </Admin>;
  }
}

export default App;

/*


export interface HelloProps { compiler: string; framework: string; }

// 'HelloProps' describes the shape of props.
// State is never set so we use the '{}' type.
export class Hello extends React.Component<HelloProps, {}> {
    render() {
        return <h1>Hello from {this.props.compiler} and {this.props.framework}!</h1>;
    }
}

import React from 'react';
import {Admin, Resource}  from 'react-admin';
import './App.css';
import PostIcon from '@material-ui/icons/Book';
import jsonServerProvider from 'ra-data-json-server';
import PostList from './posts/PostList'
import PosterList from './posters/PosterList'
import PosterEdit from './posters/PosterEdit'
import PosterCreate from './posters/PosterCreate'
import PosterIcon from '@material-ui/icons/PhotoAlbum'

function App() {
  return (
    <Admin dataProvider={jsonServerProvider('https://jsonplaceholder.typicode.com')} >
      <Resource name="posts"  options={{ label: 'Posts' }} list={PostList} icon={PostIcon} />
      <Resource name="photos" list={PosterList} edit={PosterEdit} create={PosterCreate} icon={PosterIcon} />
    </Admin>
  );
}

export default App; */
