/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Create,
    SimpleForm,
    required,
    TextInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

const FavoriteCreate = ({ permissions, ...props }: any) => (
    <Create
        {...props}
    >
        <SimpleForm>
            <ReferenceInput source="userId" reference="users">
                <AutocompleteInput  optionText="name" validate={required()} />
            </ReferenceInput>
            <TextInput
                source="name"
                validate={required()}
            />
            <TextInput source="link" validate={required()} />
            <TextInput  source="description" />
        </SimpleForm>
    </Create>
);

FavoriteCreate.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default FavoriteCreate;